import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { captureException, captureMessage } from '@sentry/react'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { createUploadLink } from 'apollo-upload-client'
import { API } from 'app'
import fetch from 'cross-fetch'
import { decode } from 'jsonwebtoken'
import {
  clearCurrentUserToken,
  getCurrentUserToken,
  setCurrentUserToken,
} from 'utils'

import type { Mutation } from './types'

const retryLink = new RetryLink()

const logError = (error: string) => {
  try {
    captureException(new Error(error))
  } catch (error_) {
    console.log('errorLink: error calling Sentry.captureException', error_)
  }
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        if (!error.message.toLowerCase().includes('not authorised')) {
          logError(JSON.stringify(error))
        }
        console.log(
          `[GraphQL error]: Message: ${
            error.message
          }, Location: ${JSON.stringify(
            error.locations,
          )}, Path: ${JSON.stringify(error.path)}`,
        )
      })

      if (
        graphQLErrors.find(
          (e) =>
            e.message.toLowerCase().includes('not authorised') ||
            e.message.toLowerCase().includes('not authorized'),
        )
      ) {
        const oldHeaders = operation.getContext().headers
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: getCurrentUserToken(),
          },
        })
        return forward(operation)
      }
    }
    if (networkError) {
      console.error(`[Network error]: ${JSON.stringify(networkError)}`)
      return forward(operation)
    }

    return forward(operation)
  },
)

const authorizationLink = setContext((_req, { headers }) => {
  const authorization = getCurrentUserToken()
  const authHeader = authorization ? { authorization } : {}

  const authConfig: {
    headers: Headers
  } = {
    headers: {
      ...headers,
      ...authHeader,
    },
  }

  return authConfig
})

const handleResponse = (response: {
  data: { refresh: Mutation['refresh'] }
  errors?: unknown[]
}) => {
  if (!response) {
    try {
      captureMessage(
        'refreshLink handleResponse: response was undefined or null',
      )
    } catch {
      console.log('refreshLink handleResponse: error calling captureMessage')
    }
    clearCurrentUserToken()
    window.location.reload()

    return
  }

  if (response.errors) {
    clearCurrentUserToken()
    window.location.reload()

    return
  }

  return { newToken: response.data.refresh.token }
}

const refreshLink = new TokenRefreshLink({
  accessTokenField: 'newToken',
  fetchAccessToken: async () => {
    const token = getCurrentUserToken()

    if (!token) {
      return null
    }

    const response = await fetch(`${process.env.REACT_APP_API}`, {
      body: JSON.stringify({
        query: `
          mutation {
            refresh {
              token
            }
          }
        `,
      }),
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    })

    return response.json()
  },
  handleError: (error) => {
    console.error('Cannot refresh access token:', error)
  },
  handleFetch: (newToken) => {
    setCurrentUserToken(newToken)
  },
  handleResponse: () => handleResponse,
  isTokenValidOrUndefined: () => {
    const token = getCurrentUserToken()

    if (!token) {
      return true
    }
    // @ts-expect-error exp exists on token
    if (token && decode(token)?.exp * 1000 > Date.now()) {
      return true
    }
    return false
  },
})

const httpLink = createUploadLink({
  credentials: 'include',
  fetch,
  uri: API,
})

const cache = new InMemoryCache()

const link = from([
  authorizationLink,
  refreshLink,
  errorLink,
  retryLink,
  // @ts-expect-error Type clash
  httpLink,
])

export const client = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
  link,
})
