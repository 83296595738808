import { ApolloProvider } from '@apollo/client'
import { CssBaseline, ThemeProvider, GlobalStyles } from '@mui/material'
import { withProfiler } from '@sentry/react'
import { client } from 'api'
import { DateAdapter, LocalizationProvider } from 'components'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Routes } from 'routes'
import { theme } from 'styles'
import { highlightProduct } from 'styles/colors'

import 'react-toastify/dist/ReactToastify.css'

function InnerContainer() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <CssBaseline />
          <GlobalStyles
            styles={{
              '.MuiAutocomplete-option[data-focus="true"]': {
                background: highlightProduct,
              },
            }}
          />
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
          <ToastContainer />
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export const Container = withProfiler(InnerContainer)
