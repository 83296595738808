import { useQuery } from '@apollo/client'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box } from '@mui/material'
import type {
  Query,
  QueryPendingInvoiceSummaryArgs,
  QueryPendingInvoicesArgs,
  PendingKitchenInvoiceSummary,
  PendingInvoiceSummary,
} from 'api'
import { QueryMode, InvoiceStatus } from 'api'
import {
  Button,
  InputAdornment,
  SearchIcon,
  TextField,
  Typography,
  Paper,
} from 'components'
import dayjs from 'dayjs'
import { useDebounce } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes'
import { CustomTable as Table } from 'screens/Upload/Table'
import { lightBlue, lightGrey } from 'styles/colors'
import { resizeUrl } from 'utils/image'

import { pendingInvoiceSummaryQuery, pendingInvoices } from './graphql'
import { ProgressBar } from './ProgressBar'

export function KitchenInvoiceList(): React.ReactElement {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string | undefined>()
  const [invoiceSummary, setInvoiceSummary] = useState<PendingInvoiceSummary>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [pendingInvoiceData, setPendingInvoiceData] = useState<any[]>()
  const [historySearch, setHistorySearch] = useState('')
  const debouncedHistorySearch = useDebounce(historySearch, 500)
  const [pagination, setPagination] = useState({
    skip: 0,
    take: 5,
  })
  const [historyPagination, setHistoryPagination] = useState({
    skip: 0,
    take: 5,
  })
  const { data, error } = useQuery<
    { pendingInvoiceSummary: Query['pendingInvoiceSummary'] },
    QueryPendingInvoiceSummaryArgs
  >(pendingInvoiceSummaryQuery, {
    variables: {
      kitchenName: searchTerm ?? undefined,
      skip: 0,
      take: 100,
    },
  })

  const { data: history } = useQuery<
    {
      pendingInvoices: Query['pendingInvoices']
      pendingInvoicesCount: Query['pendingInvoicesCount']
    },
    QueryPendingInvoicesArgs
  >(pendingInvoices, {
    variables: {
      ...historyPagination,
      where: {
        OR: debouncedHistorySearch
          ? [
              {
                createdBy: {
                  OR: [
                    {
                      firstName: {
                        contains: debouncedHistorySearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                    {
                      lastName: {
                        contains: debouncedHistorySearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  ],
                },
              },
              {
                invoice: {
                  some: {
                    supplier: {
                      name: {
                        contains: debouncedHistorySearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            ]
          : undefined,
        createdAt: {
          gte: dayjs(Date.now()).subtract(24, 'hours').startOf('day').toDate(),
        },
        status: {
          notIn: [
            InvoiceStatus.Pending,
            InvoiceStatus.Processing,
            InvoiceStatus.Merged,
          ],
        },
      },
    },
  })

  const headers = [
    'Kitchen Name',
    'Invoices Total',
    'Rejected',
    'Completed',
    'Progress',
    'Remaining',
    'Time Spent',
  ]

  const historyHeaders = [
    'Uploaded on',
    'By',
    'ID',
    'Supplier',
    'Status',
    'Images',
    'Total',
    'Date',
    'Actions',
  ]

  useEffect(() => {
    if (data?.pendingInvoiceSummary) {
      setInvoiceSummary(data.pendingInvoiceSummary)

      setPendingInvoiceData(
        data.pendingInvoiceSummary.pendingKitchenInvoiceSummary.map(
          (x: PendingKitchenInvoiceSummary) => {
            return {
              '': (
                <Box>
                  <ChevronRightIcon
                    sx={{
                      color: lightBlue,
                      marginTop: '5px',
                    }}
                  />
                </Box>
              ),
              Completed: x.invoicesCompleted,
              'Invoices Total': x.totalInvoices,
              'Kitchen Name': x.kitchenName,
              Progress: (
                <ProgressBar
                  progress={x.totalInvoices - x.invoicesRemaining}
                  steps={x.totalInvoices}
                />
              ),
              Rejected: x.invoicesRejected,
              Remaining: x.invoicesRemaining,
              'Time Spent':
                Math.floor(x.timeSpent / 60) > 0
                  ? `${Math.floor(x.timeSpent / 60)}h ${Math.round(
                      x.timeSpent % 60,
                    )}mins`
                  : `${Math.round(x.timeSpent % 60)}mins`,
              kitchenId: x.kitchenId,
            }
          },
        ),
      )
    }
  }, [data])

  if (error) {
    return <Box>{error}</Box>
  }

  const justifyContent = 'space-between'

  return (
    <Box>
      <Box
        sx={{
          borderBottom: `1px solid ${lightGrey}`,
          display: 'flex',
          justifyContent,
          marginBottom: '20px',
          paddingBottom: '20px',
        }}>
        <Typography variant="h4">Invoices</Typography>
        <Button
          color="primary"
          disabled={false}
          onClick={() => {
            navigate(routes.Upload)
          }}
          type="button"
          variant="contained">
          Add Invoices <ChevronRightIcon sx={{ marginTop: '-1px' }} />
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent,
          marginBottom: '16px',
          position: 'relative',
        }}>
        <Typography variant="h5">Pending Invoices</Typography>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
          placeholder="Filter by kitchen"
          required={false}
          size="small"
          sx={{ width: '300px' }}
          value={searchTerm}
          variant="outlined"
        />
      </Box>
      {invoiceSummary && (
        <Box
          sx={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent,
            padding: '20px',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent,
              width: '20%',
            }}>
            <Typography variant="h5">
              <Box
                component="span"
                sx={{ color: lightBlue, fontWeight: 'bold' }}>
                {invoiceSummary.pendingKitchenInvoiceSummary.length}
              </Box>{' '}
              Kitchens
            </Typography>
            <Typography variant="h5">
              <Box
                component="span"
                sx={{ color: lightBlue, fontWeight: 'bold' }}>
                {invoiceSummary.totalInvoices}
              </Box>{' '}
              Invoices
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent,
              width: '55%',
            }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ marginTop: '9px' }}>
                <ProgressBar
                  progress={
                    invoiceSummary.totalInvoicesCompleted +
                    invoiceSummary.totalInvoicesRejected
                  }
                  steps={invoiceSummary.totalInvoices}
                />
              </Box>
              <Typography variant="h5">
                <Box
                  component="span"
                  sx={{ color: lightBlue, fontWeight: 'bold' }}>
                  {invoiceSummary.totalInvoicesCompleted +
                    invoiceSummary.totalInvoicesRejected}
                  /{invoiceSummary.totalInvoices}
                </Box>{' '}
                Completed
              </Typography>
            </Box>
            <Typography variant="h5">
              <Box
                component="span"
                sx={{ color: lightBlue, fontWeight: 'bold' }}>
                {Math.floor(invoiceSummary.totalTimeSpent / 60) > 0
                  ? `${Math.floor(invoiceSummary.totalTimeSpent / 60)}h `
                  : null}
                {Math.round(invoiceSummary.totalTimeSpent % 60)}mins
              </Box>{' '}
              Spent
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ paddingBottom: '20px' }}>
        <Table
          data={
            pendingInvoiceData?.slice(
              pagination.skip,
              pagination.skip + pagination.take,
            ) ?? undefined
          }
          emptyMessage="No Invoices To Process"
          headers={[...headers, '']}
          key={1}
          onRowClick={(row) => {
            navigate(routes.UploadWithKitchen.replace(':id', row.kitchenId))
          }}
          pagination={{
            count: pagination,
            setNewParams: setPagination,
            total: pendingInvoiceData?.length ?? 0,
          }}
        />
      </Box>
      <Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent,
            paddingBottom: '20px',
          }}>
          <Typography sx={{ paddingBottom: '20px' }} variant="h5">
            Invoice History
          </Typography>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setHistorySearch(e.target.value)
            }}
            placeholder="Search for suppliers, users"
            required={false}
            size="small"
            sx={{ width: '300px' }}
            variant="outlined"
          />
        </Box>
        <Table
          data={
            history?.pendingInvoices.map((pi) => ({
              Actions: pi.invoice ? (
                <>
                  <Button
                    color="primary"
                    onClick={() => {
                      navigate(
                        routes.View.replace(':id', String(pi.invoice?.id)),
                      )
                    }}
                    sx={{ color: lightBlue, fontWeight: 'bold' }}
                    type="button">
                    VIEW
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(
                        routes.Edit.replace(':id', String(pi.invoice?.id)),
                      )
                    }}
                    sx={{ color: lightBlue, fontWeight: 'bold' }}
                    type="button">
                    EDIT
                  </Button>
                </>
              ) : (
                <Button
                  color="primary"
                  disabled={pi.status !== InvoiceStatus.Pending}
                  onClick={() => {
                    navigate(`${routes.Home}/${pi.id}`)
                  }}
                  type="button">
                  Proccess
                </Button>
              ),
              By: `${pi.createdBy.firstName} ${pi.createdBy.lastName}`,

              Date: pi.invoice?.invoiceDate
                ? dayjs(pi.invoice.invoiceDate).format('DD/MM/YYYY')
                : '-',

              ID: pi.id,

              Images: (
                <Box
                  sx={{
                    display: 'flex',
                  }}>
                  {pi.signedImages.map((image, index) => (
                    <Paper
                      elevation={5}
                      key={index}
                      sx={{
                        height: '50px',
                        margin: '10px',
                        width: '50px',
                      }}>
                      {image.toLowerCase().includes('.pdf') ? (
                        <embed
                          src={image}
                          style={{
                            height: '100%',
                            objectFit: 'cover',
                            width: '100%',
                          }}
                          type="application/pdf"
                        />
                      ) : (
                        <img
                          alt=""
                          src={resizeUrl(image, 200, 200)}
                          style={{
                            height: '100%',
                            objectFit: 'cover',
                            width: '100%',
                          }}
                        />
                      )}
                    </Paper>
                  ))}
                </Box>
              ),

              Status: `${pi.status} | ${pi.updatedBy.firstName}`,

              Supplier: pi.invoice?.supplier.name ?? '-',

              Total: pi.invoice?.invoiceTotal ?? '-',

              'Uploaded on': dayjs(pi.createdAt).format('DD/MM/YYYY HH:mm'),
            })) ?? []
          }
          emptyMessage="No Invoice History"
          headers={historyHeaders}
          pagination={{
            count: historyPagination,
            setNewParams: setHistoryPagination,
            total: history?.pendingInvoicesCount ?? 0,
          }}
        />
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          width: '50vw',
        }}
      />
    </Box>
  )
}
