import { Box, Link } from '@mui/material'
import { Typography } from 'components'
import { NavLink } from 'react-router-dom'
import { lightBlue } from 'styles/colors'

/* eslint-disable react/no-array-index-key */

export function Breadcrumb({
  crumbs,
}: {
  crumbs: { label: string; route?: string }[]
}): React.ReactElement {
  return (
    <Box sx={{ display: 'flex' }}>
      {crumbs.map(({ label, route }, index) => (
        // eslint-disable-next-line react/jsx-key
        <>
          {route ? (
            <Link
              component={NavLink}
              key={`breadcrumb-${index}`}
              sx={{ color: lightBlue, textDecoration: 'none' }}
              to={route}
            >
              <Typography variant="h4">{label}</Typography>
            </Link>
          ) : (
            <Typography key={`breadcrumb-${index}`} variant="h4">
              {label}
            </Typography>
          )}
          {index < crumbs.length - 1 && (
            <Typography
              key={`breadcrumb-${index}`}
              sx={{ padding: '0 10px' }}
              variant="h4"
            >
              {'>'}
            </Typography>
          )}
        </>
      ))}
    </Box>
  )
}
