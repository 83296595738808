import { Box, useTheme } from '@mui/material'
import { VERSION } from 'app'
import {
  AppBar,
  Button,
  ListIcon,
  CircularProgress,
  AddIcon,
  Divider,
  Drawer,
  HomeIcon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuIcon,
  Toolbar,
  Typography,
  CloudUploadIcon,
} from 'components'
import { Suspense, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { routes } from 'routes'
import { clearCurrentUserToken } from 'utils'

const menu = [
  {
    icon: <HomeIcon />,
    label: 'Home',
    route: routes.Home,
  },
  {
    icon: <AddIcon />,
    label: 'Add Invoice',
    route: routes.Create,
  },
  {
    icon: <ListIcon />,
    label: 'View Invoices',
    route: routes.List,
  },
  {
    icon: <CloudUploadIcon />,
    label: 'Upload Invoices',
    route: routes.Upload,
  },
]

export function Default(): React.ReactElement {
  const navigate = useNavigate()
  const theme = useTheme()

  const handleOnClick = () => {
    clearCurrentUserToken()
    navigate('/')
  }

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(!open)
  }
  const drawerWidth = 240

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          marginLeft: open ? drawerWidth : undefined,
          transition: theme.transitions.create(['width', 'margin'], {
            duration: open
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
          }),
          width: open ? `calc(100% - ${drawerWidth}px)` : undefined,
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            color="inherit"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{
              display: open ? 'none' : undefined,
              marginRight: 3,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            color="inherit"
            component="h1"
            noWrap
            sx={{
              flexGrow: 1,
            }}
            variant="h6"
          >
            Jelly Invoice <span>v{VERSION}</span>
          </Typography>
          <Button color="secondary" onClick={handleOnClick} variant="outlined">
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        PaperProps={{
          sx: {
            transition: theme.transitions.create('width', {
              duration: open
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
              easing: theme.transitions.easing.sharp,
            }),
            width: {
              lg: open ? drawerWidth : '55px',
              md: open ? drawerWidth : '55px',
              sm: open ? drawerWidth : '55px',
              xl: open ? drawerWidth : '55px',
              xs: open ? drawerWidth : 0,
            },
          },
        }}
        sx={{
          flexShrink: 0,
          overflowX: open ? undefined : 'hidden',
          transition: theme.transitions.create('width', {
            duration: open
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
          }),
          whiteSpace: 'nowrap',
          width: {
            lg: open ? drawerWidth : '55px',
            md: open ? drawerWidth : '55px',
            sm: open ? drawerWidth : '55px',
            xl: open ? drawerWidth : '55px',
            xs: open ? drawerWidth : 0,
          },
        }}
        variant="permanent"
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            color="inherit"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{
              marginRight: '3px',
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List dense>
          {menu.map((item) => (
            <ListItem
              button
              dense
              key={item.label}
              onClick={() => {
                navigate(item.route)
                setOpen(false)
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: theme.spacing(3),
        }}
      >
        <Box sx={theme.mixins.toolbar} />
        <Box
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <Suspense fallback={<CircularProgress />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </Box>
  )
}
