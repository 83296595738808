import { gql } from '@apollo/client'

export const pendingInvoice = gql`
  query pendingInvoice($where: PendingInvoiceWhereUniqueInput!) {
    pendingInvoice(where: $where) {
      _cursor
      createdAt
      createdBy {
        _cursor
        id
        firstName
        lastName
      }
      kitchen {
        _cursor
        id
        name
      }
      id
      images
      signedImages(download: false)
      updatedAt
      updatedBy {
        _cursor
        id
        firstName
        lastName
      }
      status
      rejectionReason
      rejectionReasonNotes
    }
  }
`

export const pendingInvoicesCheck = gql`
  query pendingInvoicesCheck($where: PendingInvoiceWhereInput) {
    pendingInvoices(where: $where) {
      _cursor
      id
    }
  }
`
