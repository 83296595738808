import { createTheme } from '@mui/material'

import { PRIMARY, SECONDARY } from './colors'

export const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  palette: {
    primary: {
      main: PRIMARY,
    },
    secondary: {
      main: SECONDARY,
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
    fontSize: 12,
  },
})
