import type { ButtonProps } from '@mui/material'
import { Button as B } from '@mui/material'
import { CircularProgress } from 'components'

export function Button(
  props: ButtonProps & { loading?: boolean },
): React.ReactElement {
  return (
    <B
      {...{
        ...props,
        children: props.loading ? (
          <CircularProgress color="inherit" size={21} />
        ) : (
          props.children
        ),
        disabled: props.loading ?? props.disabled,
      }}
    />
  )
}
