import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { captureException } from '@sentry/react'
import { InvoiceStatus, QueryMode, SortOrder } from 'api'
import type {
  Query,
  QueryMultipleSignedS3UrlsArgs,
  QueryPendingInvoicesArgs,
  MutationCreateMultiplePendingInvoiceArgs,
  MutationMergePendingInvoicesArgs,
  MutationUpdateOnePendingInvoiceArgs,
  SignedS3Url,
  PendingInvoice,
  Kitchen,
} from 'api'
import dayjs from 'dayjs'
import { useState } from 'react'
import { toast } from 'react-toastify'

import {
  signMultipleURLS,
  createMultiplePendingInvoice,
  pendingInvoices,
  mergePendingInvoices,
  updateOnePendingInvoice,
} from './graphql'

export const useHooks = ({
  setToMerge,
  historyPagination,
  pendingPagination,
  pendingSearch,
  historySearch,
  selectedKitchen,
}: {
  setToMerge: (list: number[]) => void
  pendingSearch?: string
  historyPagination?: {
    skip: number
    take: number
  }
  pendingPagination?: {
    skip: number
    take: number
  }
  historySearch?: string
  selectedKitchen?: Kitchen
}): {
  history: PendingInvoice[] | undefined
  historyCount: number | undefined
  merge: (params: { variables: MutationMergePendingInvoicesArgs }) => void
  pending: PendingInvoice[] | undefined
  pendingCount: number | undefined
  updateOne: (params: {
    variables: MutationUpdateOnePendingInvoiceArgs
  }) => void
  uploadFiles: (filelist: FileList | File[]) => void
  uploading: boolean
} => {
  const [files, setFiles] = useState<File[]>()
  const [putting, setPutting] = useState(false)

  const { data: pending, refetch: refetchPending } = useQuery<
    {
      pendingInvoices: Query['pendingInvoices']
      pendingInvoicesCount: Query['pendingInvoicesCount']
    },
    QueryPendingInvoicesArgs
  >(pendingInvoices, {
    skip: !selectedKitchen,
    variables: {
      orderBy: [
        {
          isMulti: SortOrder.Asc,
        },
        {
          createdAt: SortOrder.Desc,
        },
      ],
      ...pendingPagination,
      where: {
        OR: pendingSearch
          ? [
              {
                createdBy: {
                  OR: [
                    {
                      firstName: {
                        contains: pendingSearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                    {
                      lastName: {
                        contains: pendingSearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  ],
                },
              },
            ]
          : undefined,
        kitchenId: {
          equals: selectedKitchen?.id ?? null,
        },
        status: {
          in: [InvoiceStatus.Pending, InvoiceStatus.Processing],
        },
      },
    },
  })

  const { data: history, refetch: refetchHistory } = useQuery<
    {
      pendingInvoices: Query['pendingInvoices']
      pendingInvoicesCount: Query['pendingInvoicesCount']
    },
    QueryPendingInvoicesArgs
  >(pendingInvoices, {
    skip: !selectedKitchen,
    variables: {
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
      ...historyPagination,
      where: {
        OR: historySearch
          ? [
              {
                createdBy: {
                  OR: [
                    {
                      firstName: {
                        contains: historySearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                    {
                      lastName: {
                        contains: historySearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  ],
                },
              },
              {
                invoice: {
                  some: {
                    supplier: {
                      name: {
                        contains: historySearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            ]
          : undefined,
        createdAt: {
          gte: dayjs(Date.now()).subtract(24, 'hours').startOf('day').toDate(),
        },
        kitchenId: {
          equals: selectedKitchen?.id ?? null,
        },
        status: {
          notIn: [InvoiceStatus.Pending, InvoiceStatus.Processing],
        },
      },
    },
  })

  const refetch = () => {
    void refetchPending()
    void refetchHistory()
  }

  const [sign, { loading: signLoading }] = useLazyQuery<
    {
      MultipleSignedS3Urls: SignedS3Url[]
    },
    QueryMultipleSignedS3UrlsArgs
  >(signMultipleURLS, {
    onCompleted: ({ MultipleSignedS3Urls }) => {
      setPutting(true)
      // Upload all images to s3
      Promise.all(
        MultipleSignedS3Urls.map(({ signedURL }, index) => {
          if (!files) {
            return
          }
          const file = files[index]

          if (!file) {
            return
          }

          return fetch(signedURL, {
            body: file,
            headers: {
              'Cache-Control': 'max-age=3153600',
              'Content-Type': file.type,
            },
            method: 'PUT',
            mode: 'cors',
          })
        }),
      )
        .then(() =>
          create({
            variables: {
              imageUrls: MultipleSignedS3Urls.map(({ unsignedURL }) =>
                String(unsignedURL),
              ),

              // @ts-expect-error Kitchen not null
              kitchenId: selectedKitchen?.id,
            },
          }),
        )
        .then(() => {
          setPutting(false)
        })
        .catch((error_) => {
          setPutting(false)
          captureException(error_)
          toast.error(error_)
        })
    },
    onError: (error) => {
      captureException(error)
      toast.error(error.message)
    },
  })

  const [create, { loading: createLoading }] = useMutation<
    {
      createMultiplePendingInvoice: PendingInvoice[]
    },
    MutationCreateMultiplePendingInvoiceArgs
  >(createMultiplePendingInvoice, {
    onCompleted: (data) => {
      toast.success(
        `Successfully created ${data.createMultiplePendingInvoice.length} pending invoices`,
      )
      refetch()
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [merge, { loading: mergeLoading }] = useMutation<
    {
      mergePendingInvoices: PendingInvoice
    },
    MutationMergePendingInvoicesArgs
  >(mergePendingInvoices, {
    onCompleted: () => {
      refetch()
      toast.success(`Successfully merged invoices`)
      setToMerge([])
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [updateOne, { loading: processLoading }] = useMutation<
    {
      updateOnePendingInvoice: PendingInvoice
    },
    MutationUpdateOnePendingInvoiceArgs
  >(updateOnePendingInvoice, {
    onCompleted: () => {
      toast.success(`Invoice Updated`)
      refetch()
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const uploadFiles = (filelist: FileList | File[]) => {
    if (!selectedKitchen) {
      return
    }
    const urls = []
    const f = []
    for (const file of filelist) {
      if (!file) {
        continue
      }
      f.push(file)
      urls.push({
        extension: file.type.split('/')[1],
        filename: `PendingInvoice/${file.name.split('.')[0]}`,
        kitchenId: selectedKitchen.id,
        params: {
          ContentType: file.type,
        },
        secure: true,
      })
    }
    setFiles(f)
    sign({
      variables: {
        urls,
      },
    })
  }

  return {
    history: history?.pendingInvoices,
    historyCount: history?.pendingInvoicesCount,
    merge,
    pending: pending?.pendingInvoices,
    pendingCount: pending?.pendingInvoicesCount,
    updateOne,
    uploadFiles,
    uploading:
      mergeLoading || createLoading || signLoading || processLoading || putting,
  }
}
