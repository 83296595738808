import { Box } from '@mui/material'
import { lightBlue } from 'styles/colors'

export type Props = {
  steps: number
  progress: number
}

export function ProgressBar({ steps, progress }: Props): React.ReactElement {
  const width = (237 / steps) * progress
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        width: '250px',
      }}
    >
      <svg height="10" width="237">
        <rect
          height="10"
          rx="5"
          ry="5"
          style={{ fill: 'rgb(219,222,226)' }}
          width="237"
        />
        <rect
          height="10"
          rx="5"
          ry="5"
          style={{ fill: lightBlue }}
          width={width === 0 ? '10px' : width}
        />
      </svg>
    </Box>
  )
}
