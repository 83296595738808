export const getCurrentUserToken = (): string | null =>
  localStorage.getItem('authorization')

export const setCurrentUserToken = (token: string): void => {
  localStorage.setItem('authorization', token)
}

export const clearCurrentUserToken = (): void => {
  localStorage.removeItem('authorization')
  indexedDB.deleteDatabase('graphcache')
}
