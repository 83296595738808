// import url from 'url'

export const resizeUrl = (
  bucketLink: string,
  _width: number,
  _height: number,
): string => {
  return bucketLink
  // if (!/(s3-|s3\.)?(.*)\.amazonaws\.com\/(.*)/g.test(bucketLink)) {
  //   return bucketLink
  // }

  // const urlObject = url.parse(bucketLink)
  // const region = urlObject
  //   .hostname!.replace('s3.', '')
  //   .replace('.amazonaws.com', '')
  // const bucket = urlObject.path!.split('/')[1]
  // const key = urlObject.path!.replace(`/${bucket}`, '')
  // const extension = /\.[\da-z]+$/.exec(key)?.[0] ?? ''
  // return `http://${bucket}.s3-website.${region}.amazonaws.com${key.replace(
  //   extension,
  //   '',
  // )}_${width}x${height}${extension}`
}
