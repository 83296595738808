import { gql } from '@apollo/client'

export const adminKitchensQuery = gql`
  query AdminKitchens(
    $cursor: KitchenWhereUniqueInput
    $orderBy: [KitchenOrderByInput!]
    $skip: Int
    $take: Int
    $where: KitchenWhereInput
  ) {
    adminKitchens(
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      _cursor
      id
      name
    }
  }
`
