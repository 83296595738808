import { Box } from '@mui/material'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Loader,
} from 'components'
import { useState } from 'react'
import { lightGrey } from 'styles/colors'

export function CustomTable({
  loading,
  data,
  headers,
  emptyMessage,
  onRowClick,
  pagination,
}: {
  loading?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any[]
  headers: string[]
  emptyMessage: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowClick?: (rowData: any) => void
  pagination?: {
    count?: {
      skip?: number
      take?: number
    }
    total: number
    setNewParams: (data: { skip: number; take: number }) => void
  }
}): React.ReactElement {
  const [page, setPage] = useState(0)
  const handleChangePage = (_event: unknown, newPage: number) => {
    if (!pagination) {
      return
    }
    setPage(newPage)
    pagination.setNewParams({
      skip: newPage * (pagination.count?.take ?? 0),
      take: pagination.count?.take ?? 25,
    })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    pagination?.setNewParams({
      skip: 0,
      take: Number.parseInt(event.target.value, 10),
    })
  }

  return (
    <TableContainer sx={{ marginBottom: '20px' }}>
      {loading && (
        <Box
          sx={{
            height: '50vh',
            margin: '200px',
            position: 'absolute',
            top: '20px',
            width: '50vw',
          }}
        >
          <Loader />
        </Box>
      )}
      {data && (
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: lightGrey,
              }}
            >
              {headers.map((key) => (
                <TableCell key={key}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              backgroundColor: 'white',
            }}
          >
            {data.length > 0 ? (
              data.map((row, index) => (
                <TableRow
                  key={`row-${index}`}
                  onClick={() => {
                    onRowClick?.(row)
                  }}
                  sx={{
                    cursor: onRowClick ? 'pointer' : undefined,
                    padding: '50px',
                  }}
                >
                  {headers.map((key) => (
                    <TableCell key={key}>{row[key]}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={headers.length}
                  sx={{ textAlign: 'center' }}
                >
                  {emptyMessage}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {pagination && data && (
        <TablePagination
          component="div"
          count={pagination.total}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={pagination.count?.take ?? 10}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      )}
    </TableContainer>
  )
}
