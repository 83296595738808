import { Container, CircularProgress } from 'components'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

export function Auth(): React.ReactElement {
  return (
    <Container component="main" maxWidth="xs">
      <Suspense fallback={<CircularProgress />}>
        <Outlet />
      </Suspense>
    </Container>
  )
}
