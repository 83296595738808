import { gql } from '@apollo/client'

export const pendingInvoiceSummaryQuery = gql`
  query PendingInvoiceSummary($skip: Int!, $take: Int!, $kitchenName: String) {
    pendingInvoiceSummary(skip: $skip, take: $take, kitchenName: $kitchenName) {
      totalInvoicesRejected
      totalInvoices
      totalInvoicesCompleted
      totalTimeSpent
      pendingKitchenInvoiceSummary {
        kitchenId
        kitchenName
        timeSpent
        totalInvoices
        invoicesCompleted
        invoicesRejected
        invoicesRemaining
      }
    }
  }
`

export const pendingInvoices = gql`
  query pendingInvoices(
    $where: PendingInvoiceWhereInput
    $take: Int
    $skip: Int
  ) {
    pendingInvoicesCount(where: $where)
    pendingInvoices(where: $where, take: $take, skip: $skip) {
      _cursor
      createdAt
      createdBy {
        _cursor
        id
        firstName
        lastName
      }
      id
      signedImages(download: false)
      updatedAt
      updatedBy {
        _cursor
        id
        firstName
        lastName
      }
      status
      rejectionReason
      rejectionReasonNotes
      kitchen {
        _cursor
        id
        name
      }
      invoice {
        _cursor
        id
        supplier {
          _cursor
          id
          name
        }
        createdBy {
          _cursor
          id
          firstName
          lastName
        }
        updatedBy {
          _cursor
          id
          firstName
          lastName
        }
        invoiceDate
        invoiceId
        invoiceTotal
        vat
      }
    }
  }
`
