import { Box, CircularProgress } from '@mui/material'

export function Loader(): React.ReactElement {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={60} />
    </Box>
  )
}
