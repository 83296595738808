import { Auth as AuthLayout } from 'layouts'
// @ts-expect-error Allow import
import React, { lazy } from 'react'

import { routes } from './Paths'
import { Redirect } from './Redirect'

const SignInScreen = lazy(() =>
  import('screens/SignIn').then((module) => ({ default: module.SignIn })),
)

export const PublicRoutes = [
  {
    element: <Redirect path={routes.SignIn} />,
    path: routes.Home,
  },
  {
    children: [
      {
        element: <SignInScreen />,
        path: routes.SignIn,
      },
    ],
    element: <AuthLayout />,
    path: routes.Home,
  },
  {
    element: <Redirect path="/signin" />,
    path: '*',
  },
]
