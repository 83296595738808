import { gql } from '@apollo/client'

export const getInvoiceByIdQuery = gql`
  query invoice($where: InvoiceWhereUniqueInput!) {
    invoice(where: $where) {
      _cursor
      id
      invoiceId
      invoiceDate
      invoiceTotal
      vat
      images
      signedImages
      entries(skip: 0, take: 1000) {
        _cursor
        id
        packSize
        price
        productCode
        unitValue
        unit {
          _cursor
          id
          name
        }
        unit {
          _cursor
          id
          name
        }
        product {
          _cursor
          id
          name
        }
      }
      supplier {
        _cursor
        id
        name
      }
      kitchen {
        _cursor
        id
        name
      }
      createdAt
      createdBy {
        _cursor
        id
        firstName
        lastName
      }
    }
  }
`

export const updateOneInvoiceMutation = gql`
  mutation updateOneInvoice($id: Int!, $data: UpdateOneInvoiceInputObject!) {
    updateOneInvoice(id: $id, data: $data) {
      _cursor
      id
      invoiceId
      invoiceDate
      invoiceTotal
      vat
      entries(skip: 0, take: 1000) {
        _cursor
        id
        packSize
        price
        productCode
        unitValue
        unit {
          _cursor
          id
          name
        }
        unit {
          _cursor
          id
          name
        }
        product {
          _cursor
          id
          name
        }
      }
      supplier {
        _cursor
        id
        name
      }
      kitchen {
        _cursor
        id
        name
      }
      createdAt
      createdBy {
        _cursor
        id
        firstName
        lastName
      }
    }
  }
`
