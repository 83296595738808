import { useMutation } from '@apollo/client'
import { Box, useTheme } from '@mui/material'
import { InvoiceStatus } from 'api'
import type {
  PendingInvoice,
  Kitchen,
  Mutation,
  MutationDeleteManyPendingInvoiceArgs,
  MutationUpdateManyPendingInvoiceArgs,
} from 'api'
import {
  Typography,
  Button,
  KitchenSelect,
  Checkbox,
  CloudUploadIcon,
  TextField,
  InputAdornment,
  SearchIcon,
  Paper,
  Loader,
  BreadCrumbHeader,
  Modal,
  ImageViewer,
} from 'components'
import dayjs from 'dayjs'
import { useDebounce } from 'hooks'
import { useRef, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { routes } from 'routes'
import { lightGrey, GREY, lightBlue } from 'styles/colors'
import { resizeUrl } from 'utils/image'

import {
  deleteManyPendingInvoiceMutation,
  updateManyPendingInvoiceMutation,
} from './graphql'
import { useHooks } from './hooks'
import { CustomTable as Table } from './Table'

export function Upload(): React.ReactElement {
  const theme = useTheme()
  const { id: kitchenId } = useParams()
  const [files, setFiles] = useState<FileList | File[]>()
  const [previewImages, setPreviewImages] = useState<string[]>()
  const [pendingSearch, setPendingSearch] = useState('')
  const debouncedPendingSearch = useDebounce(pendingSearch, 500)
  const [historySearch, setHistorySearch] = useState('')
  const debouncedHistorySearch = useDebounce(historySearch, 500)

  const navigate = useNavigate()
  const [selected, setSelected] = useState<number[]>([])
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [kitchen, setKitchen] = useState<number | undefined>()
  const [selectedKitchen, setSelectedKitchen] = useState<Kitchen>()

  const [pagination, setPagination] = useState({
    skip: 0,
    take: 5,
  })
  const [historyPagination, setHistoryPagination] = useState({
    skip: 0,
    take: 5,
  })

  useEffect(() => {
    setSelected([])
  }, [selectedKitchen])
  const [deleteManyPendingInvoice] = useMutation<
    { deleteManyPendingInvoice: Mutation['deleteManyPendingInvoice'] },
    MutationDeleteManyPendingInvoiceArgs
  >(deleteManyPendingInvoiceMutation, {
    refetchQueries: ['pendingInvoices'],
  })
  const [updateManyPendingInvoice] = useMutation<
    { updateManyPendingInvoice: Mutation['updateManyPendingInvoice'] },
    MutationUpdateManyPendingInvoiceArgs
  >(updateManyPendingInvoiceMutation, {
    refetchQueries: ['pendingInvoices'],
  })
  const {
    pending,
    pendingCount,
    history,
    historyCount,
    merge,
    uploadFiles,
    uploading,
    updateOne,
  } = useHooks({
    historyPagination,
    historySearch: debouncedHistorySearch,
    pendingPagination: pagination,
    pendingSearch: debouncedPendingSearch,
    selectedKitchen,
    setToMerge: setSelected,
  })
  const inputRef = useRef(null)
  const headers = [
    'Uploaded on',
    'By',
    'ID',
    'Supplier',
    'Status',
    'Images',
    'Total',
    'Date',
    'Actions',
  ]

  const toTableData = (invoiceHistory: boolean, pis?: PendingInvoice[]) => {
    if (!pis) {
      return []
    }
    return pis.map((pi: PendingInvoice) => ({
      '':
        pi.status === InvoiceStatus.Pending ? (
          <Checkbox
            checked={selected.includes(pi.id)}
            color="primary"
            onChange={(e) => {
              setSelected(
                e.target.checked
                  ? [...selected, pi.id]
                  : selected.filter((d) => d !== pi.id),
              )
            }}
          />
        ) : (
          ''
        ),

      Actions: pi.invoice ? (
        <>
          <Button
            color="primary"
            onClick={() => {
              navigate(routes.View.replace(':id', String(pi.invoice?.id)))
            }}
            sx={{ color: lightBlue, fontWeight: 'bold' }}
            type="button">
            VIEW
          </Button>
          <Button
            onClick={() => {
              navigate(routes.Edit.replace(':id', String(pi.invoice?.id)))
            }}
            sx={{ color: lightBlue, fontWeight: 'bold' }}
            type="button">
            EDIT
          </Button>
        </>
      ) : pi.status === InvoiceStatus.Pending ? (
        <>
          <Button
            color="primary"
            onClick={() => {
              navigate(`${routes.Home}/${pi.id}`)
            }}
            type="button">
            Process
          </Button>
          <Button
            color="primary"
            onClick={() => {
              void updateOne({
                variables: {
                  data: {
                    isMulti: !pi.isMulti,
                  },
                  id: pi.id,
                },
              })
            }}
            type="button">
            {pi.isMulti ? 'Set to single' : 'Set to multi'}
          </Button>
        </>
      ) : invoiceHistory ? null : (
        <Box>
          <Box component="span" sx={{ color: GREY }}>
            LOCKED
          </Box>
          <Button
            color="primary"
            onClick={() => {
              updateOne({
                variables: {
                  data: {
                    status: InvoiceStatus.Pending,
                  },
                  id: pi.id,
                },
              })
            }}
            sx={{ color: lightBlue, fontWeight: 'bold' }}
            type="button">
            Revert to Pending
          </Button>
        </Box>
      ),
      By: `${String(pi.createdBy.firstName)} ${String(pi.createdBy.lastName)}`,

      Date: pi.invoice?.invoiceDate
        ? dayjs(pi.invoice.invoiceDate).format('DD/MM/YYYY')
        : '-',

      ID: pi.id,

      Images: (
        <Box
          onClick={() => {
            setPreviewImages(pi.signedImages)
          }}
          onKeyDown={() => ''}
          role="button"
          sx={{
            cursor: 'pointer',
            display: 'flex',
            maxWidth: '300px',
            overflow: 'auto',
          }}
          tabIndex={0}>
          {pi.signedImages.map((image: string) => (
            <Paper
              elevation={5}
              key={image}
              sx={{
                height: '50px',
                margin: '10px',
                maxWidth: '50px',
                minWidth: '50px',
              }}>
              {image.toLowerCase().includes('.pdf') ? (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <embed
                  src={image}
                  style={{
                    height: '100%',
                    objectFit: 'cover',
                    pointerEvents: 'none',
                    width: '100%',
                  }}
                  type="application/pdf"
                />
              ) : (
                <img
                  alt=""
                  src={resizeUrl(image, 200, 200)}
                  style={{
                    height: '100%',
                    objectFit: 'cover',
                    width: '100%',
                  }}
                />
              )}
            </Paper>
          ))}
        </Box>
      ),

      Status: `${pi.status} | ${String(pi.updatedBy.firstName)}`,

      Supplier: pi.invoice?.supplier.name ?? '-',

      Total: pi.invoice?.invoiceTotal ?? '-',

      'Uploaded on': dayjs(pi.createdAt).format('DD/MM/YYYY HH:mm'),
    }))
  }

  return (
    <Box>
      <BreadCrumbHeader
        crumbs={[{ label: 'Invoices', route: '/' }, { label: 'Add Invoices' }]}>
        <KitchenSelect
          kitchenId={Number.parseInt(kitchenId)}
          onChange={(kitchen) => {
            setSelectedKitchen(kitchen)
            navigate(
              routes.UploadWithKitchen.replace(
                ':id',
                kitchen?.id.toString() ?? '',
              ),
            )
          }}
        />
      </BreadCrumbHeader>

      {selectedKitchen && (
        <>
          <Box
            onClick={() => {
              // @ts-expect-error Ref exists
              inputRef.current.click()
            }}
            onDragOver={(e) => {
              e.preventDefault()
            }}
            onDrop={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (uploading) {
                return
              }
              const { files } = e.dataTransfer
              for (let i = 0; i < files.length; i++) {
                const file = files.item(i)
                if (!file) {
                  continue
                }
                if (
                  file.type !== 'application/pdf' &&
                  !file.type.includes('image/')
                ) {
                  // eslint-disable-next-line no-alert
                  window.alert(`invalid file type "${file.type}"`)
                  return
                }
              }
              setFiles(files)
            }}
            onKeyDown={() => ''}
            role="button"
            sx={{
              alignItems: 'center',
              backgroundColor: lightGrey,
              cursor: 'pointer',
              display: 'flex',
              height: '100px',
              justifyContent: 'center',
              marginBottom: '20px',
              width: '100%',
            }}
            tabIndex={0}>
            {uploading ? (
              <Loader />
            ) : (
              <>
                <Button
                  color="primary"
                  disabled={!selectedKitchen}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                  ) => {
                    e.stopPropagation()
                    // @ts-expect-error Ref exists
                    inputRef.current.click()
                  }}
                  sx={{
                    color: lightBlue,
                    fontWeight: 600,
                    paddingRight: '20px',
                  }}
                  type="button">
                  <CloudUploadIcon sx={{ marginRight: 10 }} />
                  Upload New Invoices
                </Button>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <input
                  accept="image/*,application/pdf"
                  autoComplete="off"
                  multiple
                  onChange={({ target }) => {
                    // attempt to fix empty filelist object
                    const f = []
                    for (let i = 0; i < (target.files?.length ?? 0); i++) {
                      // @ts-expect-error Files has to exist with length
                      f.push(target.files[i])
                    }
                    setFiles(f)
                  }}
                  ref={inputRef}
                  style={{
                    height: 0,
                    left: 0,
                    opacity: 0,
                    position: 'absolute',
                    width: 0,
                  }}
                  type="file"
                  value=""
                />
                <Box
                  sx={{
                    alignItems: 'center',
                    border: `1px dashed ${GREY}`,
                    color: GREY,
                    display: 'flex',
                    fontWeight: 600,
                    height: '60%',
                    justifyContent: 'center',
                    width: '40%',
                  }}>
                  {' '}
                  Or drag and drop files here
                </Box>
              </>
            )}
          </Box>
          <Box sx={{ paddingBottom: '20px' }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '20px',
              }}>
              <Typography sx={{ paddingBottom: '20px' }} variant="h5">
                Pending Invoices
              </Typography>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setPendingSearch(e.target.value)
                }}
                placeholder="Search for users"
                required={false}
                size="small"
                sx={{ width: '300px' }}
                variant="outlined"
              />
            </Box>
            <Table
              data={toTableData(false, pending)}
              emptyMessage="No Invoices To Process"
              headers={['', ...headers]}
              key={selectedKitchen.id}
              pagination={{
                count: pagination,
                setNewParams: setPagination,
                total: pendingCount ?? 0,
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button
                color="primary"
                disabled={selected.length <= 1}
                onClick={() => {
                  merge({
                    variables: {
                      ids: selected,
                    },
                  })
                }}
                type="button"
                variant="contained">
                Merge Invoices
              </Button>
              <Button
                color="primary"
                disabled={selected.length <= 0}
                onClick={() => {
                  setModal(true)
                }}
                sx={{
                  marginLeft: '10px',
                }}
                type="button"
                variant="contained">
                Move Invoices
              </Button>
              <Button
                disabled={selected.length <= 0}
                onClick={() => {
                  setDeleteModal(true)
                }}
                sx={{
                  backgroundColor:
                    selected.length <= 0 ? undefined : theme.palette.error.main,
                  color:
                    selected.length <= 0
                      ? undefined
                      : theme.palette.common.white,
                  marginLeft: '10px',
                }}
                type="button"
                variant="contained">
                Delete Invoices
              </Button>
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '20px',
              }}>
              <Typography sx={{ paddingBottom: '20px' }} variant="h5">
                Invoice History
              </Typography>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setHistorySearch(e.target.value)
                }}
                placeholder="Search for suppliers, users"
                required={false}
                size="small"
                sx={{ width: '300px' }}
                variant="outlined"
              />
            </Box>
            <Table
              data={toTableData(true, history)}
              emptyMessage="No Invoice History"
              headers={headers}
              pagination={{
                count: historyPagination,
                setNewParams: setHistoryPagination,
                total: historyCount ?? 0,
              }}
            />
          </Box>
        </>
      )}
      <Modal
        onClick={() => {
          // @ts-expect-error No need to pass undefined
          setPreviewImages()
        }}
        open={!!previewImages}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Paper
          onClick={(e) => {
            e.stopPropagation()
          }}
          sx={{
            padding: '20px',
            paddingLeft: '4px', // This is to counter the built in padding on ImageViewer, i dont want to mess with the component
          }}>
          <ImageViewer images={previewImages ?? []} />
          <Button
            onClick={() => {
              // @ts-expect-error No need to pass undefined
              setPreviewImages()
            }}>
            CLOSE
          </Button>
        </Paper>
      </Modal>
      <Modal
        onClick={() => {
          // @ts-expect-error No need to pass undefined
          setFiles()
        }}
        open={!!files}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Paper
          onClick={(e) => {
            e.stopPropagation()
          }}
          sx={{
            padding: '40px',
          }}>
          <Box>
            Do you want to upload <b>{files?.length}</b> files to{' '}
            <b>{selectedKitchen?.name}</b>
          </Box>
          <Button
            onClick={() => {
              // @ts-expect-error No need to pass undefined
              setFiles()
            }}>
            CLOSE
          </Button>
          <Button
            onClick={() => {
              if (!files) {
                return
              }

              uploadFiles(files)
              // @ts-expect-error No need to pass undefined
              setFiles()
            }}>
            CONFIRM
          </Button>
        </Paper>
      </Modal>
      <Modal
        onClick={() => {
          setModal(false)
        }}
        open={modal}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Paper
          onClick={(e) => {
            e.stopPropagation()
          }}
          sx={{
            padding: '40px',
          }}>
          <Box>
            Move {selected.length} invoice(s) from {selectedKitchen?.name} to
          </Box>
          <KitchenSelect
            onChange={(kitchen) => {
              setKitchen(kitchen?.id)
            }}
          />
          <Button
            onClick={() => {
              setModal(false)
            }}>
            CLOSE
          </Button>
          <Button
            onClick={async () => {
              const res = await updateManyPendingInvoice({
                variables: {
                  data: {
                    kitchenId: kitchen,
                  },
                  where: selected.map((id) => ({ id })),
                },
              })

              if (res.data?.updateManyPendingInvoice) {
                toast.success(
                  `Moved ${
                    res.data.updateManyPendingInvoice
                  } invoice(s) to ${String(selectedKitchen?.name)}`,
                )
              }
              setSelected([])
              setModal(false)
              // @ts-expect-error No need to pass undefined
              setKitchen()
            }}>
            CONFIRM MOVE
          </Button>
        </Paper>
      </Modal>
      <Modal
        onClick={() => {
          setDeleteModal(false)
        }}
        open={deleteModal}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Paper
          onClick={(e) => {
            e.stopPropagation()
          }}
          sx={{
            padding: '40px',
          }}>
          <Box>
            Delete {selected.length} invoice(s) from {selectedKitchen?.name}
          </Box>
          <Button
            onClick={() => {
              setDeleteModal(false)
            }}>
            CLOSE
          </Button>
          <Button
            onClick={async () => {
              const res = await deleteManyPendingInvoice({
                variables: {
                  ids: selected,
                },
              })

              if (res.data?.deleteManyPendingInvoice) {
                toast.success(
                  `Deleted ${res.data.deleteManyPendingInvoice} pending invoice(s)`,
                )
              }
              setSelected([])
              setDeleteModal(false)
            }}>
            DELETE INVOICES
          </Button>
        </Paper>
      </Modal>
    </Box>
  )
}
