import { Box } from '@mui/material'
import { Button, ClearIcon, IconButton, Typography } from 'components'
import { useRef } from 'react'
import { resizeUrl } from 'utils/image'

import { useS3Upload } from './hooks/useS3Upload'

import { Loader } from '../Loader'

export type Props = {
  imageSource?: string
  images: string[]
  signedImages: string[]
  altText?: string
  addImage: (value: string) => void
  removeImage: (value: string) => void
  mode: 'edit' | 'view' | 'add'
  loading?: boolean
  style?: React.CSSProperties
  kitchenId: number
  supplierId: number
  invoiceId: number
  secure?: boolean
}

export function MultiImageContainer({
  kitchenId,
  supplierId,
  invoiceId,
  images,
  signedImages,
  altText,
  addImage,
  removeImage,
  mode,
  secure = false,
  style,
}: Props): React.ReactElement {
  const { uploadFile, loading } = useS3Upload(
    addImage,
    secure,
    kitchenId,
    supplierId,
    invoiceId,
  )

  const inputRef = useRef(null)

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '50vw',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'lightgrey',
          display: 'flex',
          marginTop: '16px',
          minHeight: '200px',
          position: 'relative',
          width: '600px',
        }}
      >
        {mode !== 'view' && (
          <Box
            sx={{
              marginRight: 8,
              marginTop: 8,
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          >
            {!loading && (
              <Button
                color="primary"
                onClick={() => {
                  // @ts-expect-error Ref exists
                  inputRef.current.click()
                }}
                sx={{
                  height: '33px',
                  width: '120px',
                }}
                variant="contained"
              >
                Add Images
              </Button>
            )}
            {loading && <Loader />}
          </Box>
        )}
        {images.length === 0 && !loading && (
          <Typography sx={{ margin: 'auto' }} variant="h4">
            no images
          </Typography>
        )}
        <Box sx={{ maxWidth: 200 }}>
          {images.map((image, i) => {
            return (
              <Box
                key={image}
                role="button"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'relative',
                }}
              >
                {mode === 'edit' && (
                  <IconButton
                    sx={{ position: 'absolute', right: '-34px', top: '-14px' }}
                  >
                    <ClearIcon
                      onClick={() => {
                        removeImage(image)
                      }}
                      sx={{
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        color: 'white',
                        fontSize: 30,
                      }}
                    />
                  </IconButton>
                )}
                {image.toLowerCase().includes('.pdf') ? (
                  <embed
                    height="200"
                    src={signedImages[i]}
                    style={{
                      cursor: 'pointer',
                      height: '100%',
                      margin: '8px',
                      objectFit: 'cover',
                      width: '100%',
                      ...style,
                    }}
                    type="application/pdf"
                    width="200"
                  />
                ) : (
                  <img
                    alt={altText}
                    src={resizeUrl(signedImages[i], 200, 200)}
                    style={{
                      cursor: 'pointer',
                      height: '100%',
                      margin: '8px',
                      objectFit: 'cover',
                      width: '100%',
                      ...style,
                    }}
                  />
                )}
                <Button
                  color="primary"
                  onClick={() => {
                    window.open(signedImages[i], '_blank')
                  }}
                  sx={{ margin: 'auto', width: '100px' }}
                  variant="contained"
                >
                  View
                </Button>
              </Box>
            )
          })}
        </Box>

        <input
          accept="image/*,application/pdf"
          autoComplete="off"
          onChange={(event) => {
            if (!event.target.files) {
              return
            }

            uploadFile(event.target.files[0])
          }}
          ref={inputRef}
          style={{
            height: 0,
            left: 0,
            opacity: 0,
            position: 'absolute',
            width: 0,
            ...style,
          }}
          type="file"
          value=""
        />
      </Box>
    </Box>
  )
}
