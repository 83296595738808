import { useMutation, useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import type {
  Invoice,
  Mutation,
  MutationUpdateOneInvoiceArgs,
  Query,
  QueryInvoiceArgs,
} from 'api'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components'
import { MultiImageContainer } from 'components/MultiImageContainer'
import dayjs from 'dayjs'
import { isNil } from 'ramda'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { routes } from 'routes'

import { getInvoiceByIdQuery } from './graphql'

import { updateOneInvoiceMutation } from '../Edit/graphql'

export function ViewInvoice(): React.ReactElement {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, error, loading, refetch } = useQuery<
    { invoice: Query['invoice'] },
    QueryInvoiceArgs
  >(getInvoiceByIdQuery, {
    skip: isNil(id),
    variables: { where: { id: Number(id) } },
  })

  const [updateInvoice] = useMutation<
    { updateOneInvoice: Mutation['updateOneInvoice'] },
    MutationUpdateOneInvoiceArgs
  >(updateOneInvoiceMutation, {
    onCompleted: (data) => {
      if (data) {
        void refetch()
        toast.success('Successfully added image')
      }
    },
    onError: (error) => {
      console.log('Error adding invoice images', error)
      toast.error('Error adding invoice images')
    },
  })

  if (loading) {
    return <Box>Loading</Box>
  }

  if (error) {
    return <Box>{error}</Box>
  }

  const {
    invoiceId,
    invoiceDate,
    invoiceTotal,
    vat,
    createdAt,
    createdBy,
    supplier,
    kitchen,
    entries,
    images,
    signedImages,
  } = data?.invoice as Invoice
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="h5">{kitchen.name}</Typography>
          <Typography variant="h3">{supplier.name}</Typography>
        </Box>
        <Button
          color="primary"
          onClick={() => {
            navigate(routes.NextInQueue.replace(':id', kitchen.id.toString()))
          }}
          sx={{
            height: '33px',
            marginLeft: '20px',
            marginTop: '10px',
            width: '100px',
          }}
          variant="contained">
          Done
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Invoice Id</TableCell>
              <TableCell>Invoice Date</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>VAT</TableCell>
              <TableCell>Entry Date</TableCell>
              <TableCell>Entered By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="invoice">
              <TableCell>{invoiceId}</TableCell>
              <TableCell>
                {invoiceDate && dayjs(invoiceDate).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell>£{invoiceTotal}</TableCell>
              <TableCell>£{vat}</TableCell>
              <TableCell>
                {dayjs(createdAt).format('DD/MM/YYYY HH:mm')}
              </TableCell>
              <TableCell>{`${createdBy.firstName} ${createdBy.lastName}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Product Code</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((entry) => (
              <TableRow key={entry.id}>
                <TableCell>{entry.productCode}</TableCell>
                <TableCell>{entry.product.name}</TableCell>
                <TableCell>
                  {entry.packSize} x {entry.unitValue}
                  {entry.unit.name}
                </TableCell>
                <TableCell>£{entry.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          width: '50vw',
        }}>
        {data?.invoice?.id && (
          <MultiImageContainer
            addImage={(value) => {
              void updateInvoice({
                variables: {
                  data: {
                    images: [...images, value],
                  },
                  id: Number(id),
                },
              })
            }}
            altText={'Click to add a photo'}
            images={images}
            invoiceId={data.invoice.id}
            kitchenId={kitchen.id}
            loading={loading}
            mode="add"
            // eslint-disable-next-line unicorn/no-useless-undefined
            removeImage={() => undefined}
            secure
            signedImages={signedImages}
            supplierId={supplier.id}
          />
        )}

        <Box sx={{ alignItems: 'center', display: 'flex', marginTop: '16px' }}>
          <Typography sx={{ paddingRight: '8px' }} variant="h6">
            Made a mistake?{' '}
          </Typography>
          <Button
            color="primary"
            onClick={() => {
              navigate(routes.Edit.replace(':id', id))
            }}
            sx={{ width: '80px' }}
            variant="contained">
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
