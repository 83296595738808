import { gql, useLazyQuery } from '@apollo/client'
import type { QuerySignedS3UrlArgs } from 'api'
import { useState } from 'react'

const query = gql`
  query UploadImage(
    $kitchenId: Int!
    $supplierId: Int!
    $invoiceId: Int!
    $secure: Boolean
    $filename: String
    $extension: String
    $params: S3Params
  ) {
    signedS3Url(
      kitchenId: $kitchenId
      supplierId: $supplierId
      invoiceId: $invoiceId
      secure: $secure
      filename: $filename
      extension: $extension
      params: $params
    ) {
      signedURL
      unsignedURL
    }
  }
`

type SignedS3Url = {
  unsignedURL: string
  signedURL: string
}

export const useS3Upload = (
  addImage: (value: string) => void,
  secure: boolean,
  kitchenId: number,
  supplierId: number,
  invoiceId: number,
): {
  loading: boolean
  uploadFile: (file: File) => void
} => {
  const [loading, setLoading] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null)

  const [getSignedUrl] = useLazyQuery<
    {
      signedS3Url: SignedS3Url
    },
    QuerySignedS3UrlArgs
  >(query, {
    onCompleted: (res) => {
      handleCompleted(res.signedS3Url)
    },
    onError: (error) => {
      setLoading(false)
      console.log('getSignedUrl error', error)
    },
    variables: {
      invoiceId,
      kitchenId,
      params: {
        ContentType: file?.type,
      },
      secure,
      supplierId,
    },
  })

  const handleCompleted = (res: SignedS3Url) => {
    setLoading(false)
    if (file && res.signedURL && res.unsignedURL) {
      fetch(res.signedURL, {
        body: file,
        headers: {
          'Cache-Control': 'max-age=3153600',
          'Content-Type': file.type,
        },
        method: 'PUT',
        mode: 'cors',
      })
        .then(() => {
          setFile(null)

          addImage(res.unsignedURL)
        })
        .catch((_error) => {
          setFile(null)
        })
    }
  }

  const uploadFile = (file: File) => {
    setFile(file)
    setLoading(true)

    getSignedUrl({
      variables: {
        extension: file.type.split('/')[1],
        filename: ``,
        invoiceId,
        kitchenId,
        params: {
          ContentType: file.type,
        },
        secure,
        supplierId,
      },
    })
  }

  return { loading, uploadFile }
}
