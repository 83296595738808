import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  path: string
  params?: { replace?: boolean; state?: Record<string, unknown> | null } // TODO replace with correct type from react-router-dom when released
}

export function Redirect({ path, params }: Props): null {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(path, { replace: true, ...params })
  }, [navigate, params, path])
  return null
}
