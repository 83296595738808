import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import type { Invoice, Query, QueryInvoicesArgs } from 'api'
import { QueryMode, SortOrder } from 'api'
import {
  Button,
  InputAdornment,
  Paper,
  SearchIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Typography,
} from 'components'
import { Loader } from 'components/Loader'
import dayjs from 'dayjs'
import { useDebounce } from 'hooks'
import { useEffect, useState } from 'react'
import { routes } from 'routes'

import { getInvoicesQuery } from './graphql'

export function ListInvoice(): React.ReactElement {
  const [page, setPage] = useState(0)
  const [searchTerm, setSearchTerm] = useState<string | undefined>()
  const debouncedSearch = useDebounce(searchTerm, 500)
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const { data, error, loading } = useQuery<
    {
      invoices: Query['invoices']
      invoicesCount: Query['invoicesCount']
    },
    QueryInvoicesArgs
  >(getInvoicesQuery, {
    variables: {
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
      skip: page * 100,
      take: 100,
      where: {
        OR: debouncedSearch
          ? [
              {
                kitchen: {
                  name: {
                    contains: debouncedSearch,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
              {
                supplier: {
                  name: {
                    contains: debouncedSearch,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
              {
                createdBy: {
                  OR: [
                    {
                      firstName: {
                        contains: debouncedSearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                    {
                      lastName: {
                        contains: debouncedSearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  ],
                },
              },
            ]
          : undefined,
        createdAt: debouncedSearch
          ? undefined
          : { gte: dayjs(Date.now()).subtract(5, 'days').toDate },
      },
    },
  })

  useEffect(() => {
    if (data?.invoices) {
      setInvoices(data.invoices)
    }
  }, [data])

  const pagination = (
    <TablePagination
      component="div"
      count={data?.invoicesCount ?? 0}
      onPageChange={(_event: unknown, newPage: number) => {
        setPage(newPage)
      }}
      page={page}
      rowsPerPage={100}
      rowsPerPageOptions={[100]}
    />
  )
  if (error) {
    return <Box>{error}</Box>
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          marginBottom: '16px',
          position: 'relative',
        }}
      >
        <Typography variant="h4">Recently entered invoices</Typography>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          disabled={loading}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
          placeholder="Search for kitchens, suppliers, users"
          required={false}
          size="small"
          sx={{ width: '300px' }}
          value={searchTerm}
          variant="outlined"
        />
      </Box>
      <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
        {loading && (
          <Box
            sx={{
              height: '50vh',
              margin: '200px',
              position: 'absolute',
              top: '20px',
              width: '50vw',
            }}
          >
            <Loader />
          </Box>
        )}
        {pagination}
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Entry Date</TableCell>
              <TableCell>Kitchen</TableCell>
              <TableCell>Supplier</TableCell>
              <TableCell>Inv ID</TableCell>
              <TableCell>Invoice Date</TableCell>
              <TableCell>Invoice Total</TableCell>
              <TableCell>Items</TableCell>
              <TableCell>By</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow key={invoice.id}>
                <TableCell>
                  {dayjs(invoice.createdAt).format('DD/MM/YYYY HH:mm')}
                </TableCell>
                <TableCell>{invoice.kitchen.name}</TableCell>
                <TableCell>{invoice.supplier.name}</TableCell>
                <TableCell>{invoice.invoiceId}</TableCell>
                <TableCell>
                  {invoice.invoiceDate
                    ? dayjs(invoice.invoiceDate).format('DD/MM/YYYY')
                    : ''}
                </TableCell>
                <TableCell>{invoice.invoiceTotal}</TableCell>
                <TableCell>{invoice.entries.length}</TableCell>
                <TableCell>
                  {invoice.createdBy.firstName} {invoice.createdBy.lastName}{' '}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() =>
                      window.open(
                        routes.View.replace(':id', invoice.id.toString()),
                        '_blank',
                      )
                    }
                  >
                    View
                  </Button>
                  <Button
                    onClick={() =>
                      window.open(
                        routes.Edit.replace(':id', invoice.id.toString()),
                        '_blank',
                      )
                    }
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {pagination}
      </TableContainer>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          width: '50vw',
        }}
      />
    </Box>
  )
}
