import { DashboardIcon } from 'components'
import { Default as DefaultLayout, WideLayout } from 'layouts'
import { lazy } from 'react'

import { routes } from './Paths'

import { Queue } from '../screens/Dashboard/Queue'
import { EditInvoice } from '../screens/Invoices/Edit'
import { ListInvoice } from '../screens/Invoices/List'
import { ViewInvoice } from '../screens/Invoices/View'
import { KitchenInvoiceList } from '../screens/Kitchens/List'
import { Upload } from '../screens/Upload'

const DashboardScreen = lazy(() =>
  import('screens/Dashboard').then((module) => ({ default: module.Dashboard })),
)

export const PrivateRoutes = [
  {
    children: [
      {
        element: <Upload />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.Upload,
      },
      {
        element: <Upload />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.UploadWithKitchen,
      },
      {
        element: <Queue />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.NextInQueue,
      },
      {
        element: <KitchenInvoiceList />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.Home,
      },
      {
        element: <DashboardScreen />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: `${routes.Create}/`,
      },
      {
        element: <ViewInvoice />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.View,
      },
      {
        element: <EditInvoice />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.Edit,
      },
      {
        element: <ListInvoice />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.List,
      },
    ],
    element: <DefaultLayout />,
    path: routes.Home,
  },
  {
    children: [
      {
        element: <DashboardScreen />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: `${routes.Process}/:id`,
      },
    ],
    element: <WideLayout />,
    path: routes.Process,
  },
]
